<template>
  <div class="main">
    <CommonHeader/>
    <div class="title_section">
      伝票ロボット一覧
      <el-button @click="add" type="primary" ><i class="el-icon-plus"></i>伝票新規</el-button>
    </div>
    <div class="search_section">
      <div>
        <el-input placeholder="キーワード" class="input-with-select" style="width: 480px;" type="primary" v-model="searchCond.key">
          <el-select slot="prepend" placeholder="検索項目" style="width: 120px;" v-model="searchCond.keyType">
            <el-option label="伝票名" value="name"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" type="primary" @click="handleSearch" :clearable="true"></el-button>
        </el-input>
      </div>
      <div>
        <el-radio-group v-model="searchCond.robotStatus" style="margin-left:18px;" @change="handleSearch">
          <el-radio-button label="">全部</el-radio-button>
          <el-radio-button label="0">未設定</el-radio-button>
          <el-radio-button label="1">設定済</el-radio-button>
          <el-radio-button label="10">実行中</el-radio-button>
          <el-radio-button label="99">停止</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="data_table">
      <el-table
      :data="docList" width="100%">
        <el-table-column
          label="ID"
          width="80px">
          <template slot-scope="scope">
            <span>
              {{ scope.row.id }}
            </span>
           </template>
        </el-table-column>
        <el-table-column
          label="伝票"
          width="160px">
          <template slot-scope="scope">
            <span v-if="scope.row.img">
              <el-image 
                  :src="scope.row.img" 
                  :preview-src-list="[scope.row.img]"
                  style="width:100px"
                  >
              </el-image>
            </span>
            <span v-else>
              未設定
            </span>
           </template>
        </el-table-column>
        <el-table-column
          label="ロボット名">
          <template slot-scope="scope">
            <span style="font-weight: bold;font-size: 16px;">
              {{ scope.row.robotName }}
            </span><br>
            <span v-if="scope.row.status==0">
              <el-tag type="info" size="mini" >設定中</el-tag>
            </span>
            <span v-if="scope.row.status==1">
              <el-tag type="" size="mini" >設定済</el-tag>
            </span>
            <span v-if="scope.row.status==10">
              <el-tag type="success" size="mini" >実行中</el-tag>
            </span>
            <span v-if="scope.row.status==99">
              <el-tag type="warning" size="mini" >停止</el-tag>
            </span>
          </template>
          
        </el-table-column>
        <el-table-column
          label="作成者"
          width="160px">
          <template slot-scope="scope">
          <span>
            {{ scope.row.creator }}
          </span>
          </template>
        </el-table-column>
        <el-table-column
          label="作成時間"
          width="160px">
          <template slot-scope="scope">
          <span>
            {{ scope.row.createTime }}
          </span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="220px">
          <template slot-scope="scope">
            <el-button size="mini" type="" @click="handleSetting(scope.row.id)" plain>設定</el-button>
            <el-button size="mini" type="success" @click="handleStart(scope.row.id)" v-if="scope.row.status==1 || scope.row.status==99" plain>起動</el-button>
            <el-button size="mini" type="" @click="handleStop(scope.row.id)" v-if="scope.row.status==10" plain>停止</el-button>
            <el-button size="mini" type="warning" @click="handleDelete(scope.row.id)" plain>删除</el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <el-empty description="該当データがありません" style="margin:auto"></el-empty>
        </template>
      </el-table>
    </div>
    <div style="flex:1"></div>
    <CommonFooter />
    <el-dialog title="ロボット新規" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="ロボット名" :label-width="300">
          <el-input v-model="form.robotName" autocomplete="off" style="width: 70%;"></el-input>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="handleAdd">確認</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CommonHeader from "@/components/common/CommonHeader.vue";
import CommonFooter from "@/components/common/CommonFooter.vue";

import { isValidResponse } from "@/utils/commonUtils";
import { ROUTER_PATH } from "@/constants/common.js";
import { mapState } from "vuex";
import { listRobots,addRobot,deleteRobot } from "@/api/kintone";

export default {
  name: "DocList",
  components: {
    CommonHeader,
    CommonFooter
  },
  data: () => {
    return {
      searchCond:{
        key:"",
        keyType:"name",
        robotStatus:""

      },
      filterStatus: [],
      docList:[],
      dialogFormVisible: false,
      form:{}
    };
  },
  mounted() {
    listRobots({
    }).then(response => {
      this.docList = response.data;
    });
  },
  created: function () {
  },
  computed: {
    ...mapState(["accountInfo"]),
  },
  methods: {
    handleSearch() {
      listRobots({
        robotName:this.searchCond.key,
        status: this.searchCond.robotStatus
      }).then(response => {
        this.docList = response.data;
      });
    },
    handleSetting(id) {
      this.$router.push({name:"DocConfig",params:{id:id}});
    },
    add(){
      this.dialogFormVisible= true;
      this.form = {name:""};
    },
    handleAdd(){
      addRobot({
        robotName:this.form.robotName,
        status:0
      }).then(response => {
        if(isValidResponse(response)){
          listRobots({
          }).then(response => {
            this.docList = response.data;
          });
          this.$message({
            message: "追加しました",
            type: "success"
          });
        }
        this.dialogFormVisible = false;
      });
    },
    handleDelete(id){
      deleteRobot({
        id:id
      }).then(response => {
        if(isValidResponse(response)){
          listRobots({
          }).then(response => {
            this.docList = response.data;
          });
          this.$message({
            message: "削除しました",
            type: "success"
          });
        }
      });
    },
    handleStart(id){
      addRobot({
        id:id,
        status:10
      }).then(response => {
        if(isValidResponse(response)){
          listRobots({
          }).then(response => {
            this.docList = response.data;
          });
          this.$message({
            message: "起動しました",
            type: "success"
          });
        }
        this.dialogFormVisible = false;
      });
    },
    handleStop(id){
      addRobot({
        id:id,
        status:99
      }).then(response => {
        if(isValidResponse(response)){
          listRobots({
          }).then(response => {
            this.docList = response.data;
          });
          this.$message({
            message: "停止しました",
            type: "success"
          });
        }
        this.dialogFormVisible = false;
      });
    },
    
  },
};
</script>
<style scoped>
.title_section {
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content: space-between;
  width: 1060px;
  margin-top: 32px;
  font-size: 18px;
  font-weight: bold;
}
.search_section {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  background-color: #fff;
  width: 1024px;
  margin-top: 16px;
  padding: 18px;
  border-radius: 12px 12px 0px 0px;
}
.data_table {
  width: 1024px;
  margin-top: 4px;
  padding: 18px;
  border-radius: 0px 0px 12px 12px;
  background-color: #fff;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>

